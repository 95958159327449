/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import LayoutHome from "../layout/LayoutHome";

const PrivacyPolicy = () => {
  return (
    <LayoutHome seoTitle="Privacy and Policy">
      <React.Fragment>
        <span sx={{ color: "#1ca0f2", mt: "30px" }}>
          PRIVACY AND COOKIE POLICY
        </span>
        <p>
          We, Sahaj Marg Spirituality Foundation India, are committed to
          protecting and respecting your privacy.
        </p>
        <p>
          This policy sets out the basis on which any personal data we collect
          from you, or that you provide to us, via our website, mobile apps,
          voice-based apps and other digital media and when you attend our
          events, make donations or interact with us in some other way will be
          processed by us.
        </p>
        <p>
          Please read the following carefully to understand our views and
          practices regarding your personal data and how we will treat it.
        </p>
        <p>
          For the purposes of European Economic Area data protection law, (the
          `Data Protection Law`), the data controller for your personal data is
          Sahaj Marg Spirituality Foundation, having its registered office at
          Admin Block, No 3/246, Babuji Memorial Ashram, SRCM Road, Manapakkam,
          Chennai, Tamil Nadu – 600125 and Registered as a Charitable Trust
          under Indian Registration Act 1908 in Tamil Nadu.
        </p>
        <p>
          makjWe will share your personal data for the purposes set out in this
          policy with the other affiliate organisations across the world. More
          details about the sharing of data with the other affiliate
          organisations can be found in the{" "}
          <a href="#who-we-share">`Who we give your information to`</a> section.
        </p>
        <p>In particular, this policy (together with Terms of Use) sets out:</p>
        <details sx={{ p: 3 }}>
          <summary>Information we collect about you</summary>
          <p>
            We will collect and process the following personal data from you:
          </p>
          <p>Information you give us</p>
          <p>
            This is information about you that you give us by filling in forms
            on our Website or by corresponding with us by phone, e-mail or
            otherwise. It includes information you provide when you register to
            use our Website, subscribe to our service, make a donation on our
            Website, participate in discussion boards or other social media
            functions on or via our Website, enter a competition, promotion or
            survey, submit a query, and when you report a problem with our
            Website.
          </p>
          <p>
            The information you give us may include your name, PAN, Passport
            Number, address, e-mail address and phone number, personal
            description and photograph, login and password details.
          </p>
          <p>Information we collect about you from your use of our Website:</p>
          <p>
            We will automatically collect information from you each time you use
            our Website. This includes:
          </p>
          <p>
            <b> echnical information</b> may include the Internet protocol (IP)
            address used to connect your computer to the Internet, your login
            information, browser type and version, time zone setting, browser
            plug-in types and versions, operating system and platform;
          </p>
          <p>
            <b>Information about your visit</b>may include the full Uniform
            Resource Locators (URL), clickstream to, through and from our site
            (including date and time), products you viewed or searched for, page
            response times, download errors, length of visits to certain pages,
            page interaction information (such as scrolling, clicks, and
            mouse-overs), methods used to browse away from the page, and any
            phone number used to call our customer service number or social
            media handle used to connect with our customer service team.
          </p>
          <p>
            <b>Location data</b> – we collect information through the Website as
            to your real-time location to provide location services where
            requested or agreed to by you in order to allow tagging, allow
            check-in, deliver content, advertising or other services such as
            event information that are dependent on knowing where you are. This
            information is/may also be collected in combination with an
            identifier associated with your device to enable us to recognize
            your mobile browser or device when you return to the site/App.
          </p>
          <p>
            Delivery of location services will involve reference to one or more
            of the following: (a) the coordinates (latitude/longitude) of your
            location; (b) look-up of your country of location by reference to
            your IP address against public sources; and/or (c) your Identifier
            for Advertisers (IFA) code for your Apple device, or the Android ID
            for your Android device, or a similar device identifier.
          </p>
          <p>
            See Cookies and other technologies for more information on the use
            of cookies and device identifiers on the Website.
          </p>
          <p>Information we receive from other sources.</p>
          <p>This is information we receive about you:</p>
          <ul>
            <li>
              If you use any of the other websites or apps we operate or the
              other services we provide.
            </li>
            <li>
              Where you use the meditation services of other Heartfulness
              organisations around the world
            </li>
            <li>From third parties we work closely with.</li>
          </ul>
          <p>
            In this case we will have informed you when we collected that data
            if we intend to share your data internally and combine it with data
            collected on this site. We will also have told you for what purpose
            we will share and combine your data.
          </p>
          <p>
            We will be combining the data we receive from other heartfulness
            organisations when you register for events online or in our
            meditation centres, when you make enquiries through email, website
            or other communication channels or when you volunteer with any of
            our affiliate organisations.
          </p>
          <p>
            We are working closely with third parties (including, for example,
            sub-contractors in technical, payment and delivery services,
            advertising networks, analytics providers, and search information
            providers). We will notify you when we receive information about you
            from them and the purposes for which we intend to use that
            information.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Cookies and other technologies</summary>
          <p>
            Our Website uses cookies and/or other similar technologies such as
            pixel tags or web beacons to collect and store certain information.
            These typically involve pieces of information or code that a website
            transfers to or accesses from your computer hard drive or mobile
            device to store and receive information about you. Cookies and
            similar technologies enable you to be remembered when using that
            computer or device to interact with websites and online services and
            can be used to manage a range of features and content as well as
            storing searches and presenting personalised content.
          </p>
          <p>
            Our Website uses cookies and similar technologies to distinguish you
            from other users of our Website. This helps us to provide you with a
            good experience when you browse our Website and also allows us to
            improve our Website.
          </p>
          <p>
            Most web browsers automatically accept cookies and similar
            technologies, but if you prefer, you can change your browser to
            prevent that and your help screen or manual will tell you how to do
            this. We also give you information about how to disable cookies
            here. However, you may not be able to take full advantage of our
            website if you do so.
          </p>
          <p>
            A number of cookies and similar technologies we use last only for
            the duration of your web or app session and expire when you close
            your browser/or exit the Website]. Others are used to remember you
            when you return to the Website and will last for longer.
          </p>
          <p>
            We use these cookies and other technologies on the basis that they
            are necessary for the performance of a contract with you, or because
            using them is in our legitimate interests in improving our Website
            and engaging in a focused way which understand our users (where we
            have considered that these are not overridden by your rights).
          </p>
          <p>
            We use cookies and/or other similar technologies, such as tracking
            GIFs, web beacons pixel codes either alone or in combination with
            each other to create a unique device ID.
          </p>
          <p>
            We use the following types of cookies:[this is a standard list of
            e-commerce cookies but you should check and delete where
            appropriate]
          </p>
          <p>
            <b>Strictly necessary</b> cookies. These are cookies that are
            required for the operation of our website and under our terms with
            you. They include, for example, cookies that enable you to log into
            secure areas of our website, use a shopping cart or make use of
            e-billing services.
          </p>
          <p>
            <b>Analytical/performance</b> cookies. They allow us to recognize
            and count the number of visitors and to see how visitors move around
            our website when they are using it. This helps us for our legitimate
            interests of improving the way our website works, for example, by
            ensuring that users are finding what they are looking for easily.
          </p>
          <p>
            <b>Functionality</b> cookies. These are used to recognize you when
            you return to our website. This enables us, subject to your choices
            and preferences, to personalize our content, greet you by name and
            remember your preferences (for example, your choice of language or
            region).
          </p>
          <small>Disabling cookies</small>
          <p>
            The effect of disabling cookies depends on which cookies you disable
            but, in general, the website may not operate properly if all cookies
            are switched off.
          </p>
          <p>
            If you want to disable cookies on our site, you need to change your
            website browser settings to reject cookies. How you can do this will
            depend on the browser you use. Further details on how to disable
            cookies for the most popular browsers are set out below: –
          </p>
          <p>
            <b>For Microsoft Internet Explorer:</b>
            <br />
            1. Choose the menu `tools` then `Internet Options`
            <br />
            2. Click on the `privacy` tab
            <br />
            3. Select the setting the appropriate setting
          </p>
          <p>
            <b>For Google Chrome:</b>
            <br />
            1. Choose Settings {`>`} Advanced
            <br />
            2. Under `Privacy and security` click `Content settings`.
            <br />
            3. Click `Cookies`
          </p>
          <p>
            <b>For Safari:</b>
            <br />
            1. Choose Preferences {`>`} Privacy
            <br />
            2. Click on `Remove all Website Data`
          </p>
          <p>
            <b>For Mozilla Firefox:</b>
            <br />
            1. Choose the menu `tools` then `Options`
            <br />
            2. Click on the icon `privacy`
            <br />
            3. Find the menu `cookie` and select the relevant options
          </p>
          <p>
            <b>For Opera 6.0 and further:</b>
            <br />
            1. Choose the menu Files {`>`} `Preferences`
            <br />
            2. Privacy
          </p>
          <p>
            Where you have not set your permissions, we may also separately
            prompt you regarding our use of cookies on the Site.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>How we use your information</summary>
          <p>We use information held about you in the following ways:</p>
          <p>Information you give to us:</p>
          <p>We will use this information to:</p>
          <ul>
            <li>
              Take steps in order to provide meditation services and anything
              else we agree to provide including:
              <br />
              <ul>
                <li>administering your account with us;</li>
                <li>providing meditation guidance and education</li>
                <li>
                  where you make a donation, verifying and carrying out the
                  processing of payment you make, including through the Website
                  and providing those information to Indian Government as
                  required under those laws;
                </li>
              </ul>
            </li>
            <li>
              To assist our volunteer facilitators to contact you with
              information about us and our events taking place in your area by
              your chosen method of communication if you have given your consent
              to receiving information from us;
            </li>
            <li>
              To assist our volunteer facilitators, practitioners, trainers and
              event co-ordinators to administer, manage and organise events you
              have expressed an interest in attending;
            </li>
            <li>
              Ensure that:
              <br />
              <ul>
                <li>
                  content from our site is presented in the most effective
                  manner for you and for your computer.
                </li>
                <li>
                  we provide you with the information, products and services
                  that you request from us.
                </li>
              </ul>
            </li>
          </ul>
          <p>Information we collect about you from your use of our Website</p>
          <p>
            We will use this information in our legitimate interests, where we
            have considered these are not overridden by your rights:
          </p>
          <ul>
            <li>
              To administer our Website and for internal operations, including
              troubleshooting, data analysis, testing, research, statistical and
              survey purposes.
            </li>
            <li>To keep our Website safe and secure.</li>
            <li>
              For measuring or understanding the effectiveness of advertising we
              serve to you and others, and to deliver relevant advertising to
              you.
            </li>
            <li>
              To improve our Website to ensure that content is presented in the
              most effective manner for you and for your computer.
            </li>
            <li>
              To allow you to participate in interactive features of our
              service, when you choose to do so.
            </li>
          </ul>
          <p>
            <b>Information we receive from other sources</b>
            <br />
            We will combine this information with information you give to us and
            information we collect about you in our legitimate interests (where
            we have considered that these are not overridden by your rights). We
            will use this information and the combined information for the
            purposes set out above (depending on the types of information we
            receive).
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Our promotional updates and communications</summary>
          <p>
            Where permitted in our legitimate interest or with your prior
            consent where required by law, we will use your personal information
            to provide you with promotional update communications by email, post
            and telephone about our products and services.
          </p>
          <p>
            You can object to further marketing at any time by checking and
            updating your contact details within your account, or selecting the
            `unsubscribe` link at the end of all our marketing and promotional
            update communications to you, or by sending us an email to
            <a href="mailto:webmaster@heartfulness.org">
              webmaster@heartfulness.org
            </a>
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Our legal basis for holding your information</summary>
          <p>We hold your information for one of three reasons:</p>
          <p>
            For the purposes of a contract with you, where we need information
            like your name and contact details so we can provide meditation
            sessions and guidance at your request;
          </p>
          <p>
            For our legitimate interests, having considered any impact on your
            interests and rights, for instance in promoting our organisation and
            the Heartfulness mission, in requesting and processing donations to
            fund our activities, to learn from our participants, donors and
            volunteers so we can improve our Website, our meditation sessions
            and education and their general experience of Heartfulness; and
          </p>
          <p>
            To comply with our legal obligations as per Indian Legal Acts
            (Income Tax Act, Foreign Contribution Regulation Act etc) and
            retaining records of donations under those regulations.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary id="who-we-share">Who we give your information to</summary>
          <p id="who-we-share">We may give your information to:</p>
          <ul>
            <li>
              Our volunteer facilitators, practitioners, trainers and event
              co-ordinators worldwide for the administration, management and
              organisation of events;
            </li>
            <li>
              Other similar organisations, the Heartfulness Institute,
              Heartfulness Educational Trust, Shri Ram Chandra Mission, and
              Spiritual Hierarchy Publication Trust
            </li>
            <li>
              The above mentioned organisations will use your information in
              accordance with this policy in the general operation, management
              and support of our activities and in its responsibility for the
              planning and strategy for the Heartfulness mission
            </li>
          </ul>
          <p>Our selected third parties may include:</p>
          <ul>
            <li>
              Organisations who process your personal data on our behalf and in
              accordance with our instructions and the Data Protection Law. This
              includes in supporting the services we offer through the Website
              in particular those providing website and data hosting services,
              providing fulfilment services, distributing any communications we
              send, supporting or updating marketing lists, facilitating
              feedback on our services and providing IT support services from
              time to time. These organisations (which may include third party
              suppliers, agents, sub-contractors and/or other companies in our
              group) will only use your information to the extent necessary to
              perform their support functions.
            </li>
            <li>
              Analytics and search engine providers that assist us in the
              improvement and optimisation of our site and subject to the cookie
              section of this policy (this will not identify you as an
              individual).
            </li>
            <li>
              Payment processing providers who provide secure payment processing
              services.
            </li>
          </ul>
          <p>We will disclose your personal information to third parties:</p>
          <ul>
            <li>
              If we merge with another organisation or are re-organised in order
              to sustain or further the Heartfulness mission and substantially
              all of its assets are acquired by a third party, in which case
              personal data held by us will also be transferred to the other
              organisation.
            </li>
            <li>
              If we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation, or in order to enforce
              or apply our terms of supply terms and other agreements with you;
              or to protect the rights, property, or safety of Heartfulness
              India, our customers, or others. This includes exchanging
              information with other companies and organisations for the
              purposes of fraud protection and to prevent cybercrime.
            </li>
          </ul>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Where we store your information</summary>
          <p>
            The data that we collect from you may be transferred to, and stored
            at, a destination outside the European Economic Area (`EEA`) that
            may not be subject to equivalent Data Protection Law.
          </p>
          <p>
            Where your information is transferred outside the EEA, we will take
            all steps reasonably necessary to ensure that your data is subject
            to appropriate safeguards, such as relying on a recognised legal
            adequacy mechanism, and that it is treated securely and in
            accordance with this privacy policy.
          </p>
          <p>We do not transfer your personal information outside the EEA:</p>
          <ul>
            <li>In order to store it.</li>
            <li>
              In order to enable us to provide the services and guidance you ask
              of us.This includes events, meditation sessions, processing of
              payment details, and the provision of support services.This
              includes transfer to our affiliate organisations worldwide as set
              out in the section
              <a href="#who-we-share">`Who we share your information with`</a>
            </li>
            <li>Where we are legally required to do so.</li>
            <li>
              In order to facilitate the operation of our group of businesses,
              where it is in our legitimate interests and we have concluded
              these are not overridden by your rights.
            </li>
            <li>
              We may transfer your personal information to the following
              countries outside the EEA: India, USA.
            </li>
            <li>
              Where we share your data with other Heartfulness entities outside
              the EEA, we have signed EU-approved standard contractual clauses
              with each of them to safeguard your information.
            </li>
          </ul>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Public forums</summary>
          <p>
            The Website may, from time to time, make chat rooms, message boards,
            news groups and/or other public forums available to its users. Any
            information that is disclosed in these areas becomes public
            information and you should exercise caution when using these and
            avoid posting any personal information.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Child safety</summary>
          <p>
            Protecting the safety of children when they use the Internet is
            important to us.
          </p>
          <p>
            The Website is intended for use only by persons who are at least
            [18] years of age. By using the Website, you confirm to us that you
            meet this requirement. If you are under the age of 18, you confirm
            you have received permission from your parent or guardian before
            using this site/App or sending us personal information.
          </p>
          <p>
            If you are under the age of [13] your parent or guardian must
            consent on your behalf where we ask for consent in relation to the
            use of your information.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Payment processing</summary>
          <p>
            Payment details you provide will be encrypted using secure sockets
            layer (SSL) technology before they are submitted to us over the
            internet. Payments made on the Website are made through our payment
            gateway providers, Instamojo, Ccavenue Authorize.net and Pay Pal.
            You will be providing credit or debit card information directly to
            payment gateway providers which operates a secure server to process
            payment details, encrypting your credit/debit card information and
            authorising payment. Information which you supply to payment gateway
            providers is not within our control and is subject to payment
            gateway providers own privacy policy and terms and conditions.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>How we protect your information</summary>
          <p>
            All information you provide to us is stored on our secure servers.
            Any payment transactions will be encrypted using SSL technology.
            Where we have given you (or where you have chosen) a password which
            enables you to access certain parts of our Website, you are
            responsible for keeping this password confidential. We ask you not
            to share a password with anyone.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we will do our best to protect your
            personal data, we cannot guarantee the security of your data
            transmitted to our Website; any transmission is at your own risk.
            Once we have received your information, we will use strict
            procedures and security features to try to prevent unauthorised
            access.
          </p>
          <p>
            Our site may, from time to time, contain links to external sites. We
            are not responsible for the privacy policies or the content of such
            sites.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>How long we keep your information</summary>
          <p>
            We retain personal data for for as long as you are a registered
            meditator, donor or volunteer with us in order to meet our
            contractual obligations to you in our legitimate interests for as
            long as is permitted under Data Protection Law for instance in order
            to comply with our legal obligations. We may also retain aggregate
            information beyond this time for research purposes and to help us
            develop and improve our services. You cannot be identified from
            aggregate information retained or used for these purposes.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Your rights</summary>
          <ul>
            <li>
              You have the right under certain circumstances:
              <br />
              <ul>
                <li>
                  to be provided with a copy of your personal data held by us;
                </li>
                <li>
                  to request the rectification or erasure of your personal data
                  held by us;
                </li>
                <li>
                  to request that we restrict the processing of your personal
                  data (while we verify or investigate your concerns with this
                  information, for example);
                </li>
                <li>
                  to object to the further processing of your personal data,
                  including the right to object to marketing (as mentioned in
                  ‘Our promotional updates and communications’ section;
                </li>
                <li>
                  to request that your provided personal data be moved to a
                  third party
                </li>
              </ul>
            </li>
            <li>
              Your right to withdraw consent:
              <br />
              Where the processing of your personal information by us is based
              on consent, you have the right to withdraw that consent without
              detriment at any time by contacting us. You can also change your
              marketing preferences at any time as described in ‘Our promotional
              updates and communications’ section;
            </li>
          </ul>
          <p>
            You can also exercise the rights listed above at any time by
            contacting us at
            <a href="mailto:privacy@heartfulness.org">
              privacy@heartfulness.org
            </a>
            .
          </p>
          <p>
            For the purposes of European Economic Area data protection law, If
            your request or concern is not satisfactorily resolved by us, you
            may approach your local data protection authority, (see
            <a
              href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html"
              target="_blank"
              rel="noreferrer"
            >
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html
            </a>
            ).
          </p>
          <p>
            Your data protection authority can provide further information about
            your rights and our obligations in relation to your personal data,
            as well as deal with any complaints that you have about our
            processing of your personal data.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Changes to this policy</summary>
          <p>
            Any changes we make to our privacy policy in future will be posted
            on this page and, in relation to substantive changes, will be
            notified to you by e-mail. This policy is effective from
            25-May-2018.
          </p>
        </details>
        <details sx={{ p: 3 }}>
          <summary>Contact us</summary>
          <p>
            Questions, comments and requests regarding this privacy policy are
            welcomed and should be addressed to:
          </p>
          <p>Sahaj Marg Spirituality Foundation</p>
          <p>
            Admin Block, No 3/246, Babuji Memorial Ashram,
            <br />
            SRCM Road, Manapakkam,
            <br />
            Chennai, Tamil Nadu – 600125
          </p>
          <a href="mailto:webmaster@heartfulness.org">
            webmaster@heartfulness.org
          </a>
        </details>
      </React.Fragment>
    </LayoutHome>
  );
};

export default PrivacyPolicy;
